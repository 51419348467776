
function Contact() {
  return (
    <div className="ml-5">
      <h1 className="white sub" id="contact">Connect with Me</h1>
      <div className="display">
        <a className="mr-5" href="https://www.linkedin.com/in/ryan-mau-732b88233/" target="_blank"><i class="fa fa-linkedin-square font-30 primary icon"></i></a>
        <a href="mailto:rycmau@gmail.com?Subject=I'd like to connect!"><i class="fa fa-light fa-envelope font-30 primary icon"></i></a>
      </div>
    </div>

  );
}

export default Contact;
