import headshot from '../resources/headshot.jpg';

function Personal() {
  return (
      <div>
        <div className="display-left ml-5 w-80 mt-5">
          <div>
            <h1 className="primary sub-22 mb-0">Hi, my name is Ryan.</h1>
            <h1 className="heading white">I build veterinary software.</h1>
            <h1 className="primary sub">I am a full-stack software engineer with experience in React.js, Spring Boot, and SolidJS.</h1>
            <h1 className="white sub-two">A major passion of mine is the veterinary medicine industry. Due to family ties to the field, it has a
            special place in my life. My goal is to do all I can do use my skills to further the field anyway I can.</h1>
          </div>
        </div>
      </div>
  );
}

export default Personal;
