
function Projects() {
  return (
    <div className="display ml-5 m-5 mr-5">
      <div className="w-100">
        <h1 className="white sub" id="mywork">My Work</h1>

        <div className="display card">
          <div className="display-left w-30 mr-5">
            <h1 className="timeframe primary ta-left">August 2023 - Current</h1>
          </div>
          <div className="display-left w-70">
            <div className="w-100 ta-left">
              <h1 className="exp-title primary"><a className="primary" href="https://www.underbite.app" target="_blank">Underbite - Founder/Creator<i class="fa fa-light fa-arrow-up-right-from-square primary ml-5"></i></a></h1>
              <h1 className="exp-desc primary">Created a veterinary dental charting software called Underbite. A cloud-based platform which allows veterinarians to create, edit, and export dental charts seemlessly.</h1>
              <div className="display-wrap w-100 m-5">
                <div className="tech-blob secondary mr-5">Java</div>
                <div className="tech-blob secondary mr-5">JavaScript</div>
                <div className="tech-blob secondary mr-5">HTML</div>
                <div className="tech-blob secondary mr-5">CSS</div>
                <div className="tech-blob secondary mr-5">Thymeleaf</div>
                <div className="tech-blob secondary mr-5">Spring</div>
                <div className="tech-blob secondary mr-5">htmx</div>
                <div className="tech-blob secondary mr-5">MongoDB</div>
              </div>
            </div>
          </div>
        </div>

        <div className="display card">
          <div className="display-left w-30 mr-5">
            <h1 className="timeframe primary ta-left">January 2023 - June 2023</h1>
          </div>
          <div className="display-left w-70">
            <div className="w-100 ta-left">
              <h1 className="exp-title primary"><a className="primary" href="https://www.team2.com" target="_blank">Team2 - Junior Developer Internship<i class="fa fa-light fa-arrow-up-right-from-square primary ml-5"></i></a></h1>
              <h1 className="exp-desc primary">Developed and styled new frontend functionality within a custom-built system
                infrastructure. Responsible for connecting backend data to the frontend, and building a user-friendly UI.</h1>
              <div className="display-wrap w-100 m-5">
                <div className="tech-blob secondary mr-5">JavaScript</div>
                <div className="tech-blob secondary mr-5">HTML</div>
                <div className="tech-blob secondary mr-5">CSS</div>
                <div className="tech-blob secondary mr-5">PHP</div>
                <div className="tech-blob secondary mr-5">JQuery</div>
                <div className="tech-blob secondary mr-5">Bootstrap</div>
                <div className="tech-blob secondary mr-5">Agile</div>
              </div>
            </div>
          </div>
        </div>

        <div className="display card">
          <div className="display-left w-30 mr-5">
            <h1 className="timeframe primary ta-left">November 2022 - May 2023</h1>
          </div>
          <div className="display-left w-70">
            <div className="w-100 ta-left">
              <h1 className="exp-title primary"><a className="primary" href="https://vet.ryanmau.com" target="_blank">Vet Practice Management System<i class="fa fa-light fa-arrow-up-right-from-square primary ml-5"></i></a></h1>
              <h1 className="exp-desc primary">Built a cloud-based veterinary practice management system which allows veterinary
                clinics to manage their clinic data on a singular platform.</h1>
              <div className="display-left w-100">
                <h1 className="exp-opt primary">Test system login info: testuser (username), test (password)</h1>
              </div>
              <div className="display-wrap w-100 m-5">
                <div className="tech-blob secondary mr-5">Spring</div>
                <div className="tech-blob secondary mr-5">SolidJS</div>
                <div className="tech-blob secondary mr-5">TypeScript</div>
                <div className="tech-blob secondary mr-5">HTML</div>
                <div className="tech-blob secondary mr-5">CSS</div>
                <div className="tech-blob secondary mr-5">MySQL</div>
                <div className="tech-blob secondary mr-5">Github</div>
              </div>
            </div>
          </div>
        </div>

        <div className="display card">
          <div className="display-left w-30 mr-5">
            <h1 className="timeframe primary ta-left">Personal Project</h1>
          </div>
          <div className="display-left w-70">
            <div className="w-100 ta-left">
              <h1 className="exp-title primary"><a className="primary" href="https://movies.ryanmau.com" target="_blank">Random Movie Generator<i class="fa fa-light fa-arrow-up-right-from-square primary ml-5"></i></a></h1>
              <h1 className="exp-desc primary">Built a web app with the React framework which randomly generates movies for the user
                from a public movie API.</h1>
              <div className="display-wrap w-100 m-5">
                <div className="tech-blob secondary mr-5">React JS</div>
                <div className="tech-blob secondary mr-5">HTML</div>
                <div className="tech-blob secondary mr-5">CSS</div>
                <div className="tech-blob secondary mr-5">Github</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Projects;
