import Navbar from "../components/Navbar";
import Personal from "../components/Personal";
import Projects from "../components/Projects";
import Contact from "../components/Contact";

function Home() {
  return(
    <div className="home ml-3 vw-95 pb-10">
      <Navbar />
      <div>
      <Personal />
      <Projects />
      </div>
      <div>
        <Contact />
      </div>
    </div>
  );
}

export default Home;
