import headshot from '../resources/headshot.jpg';
import {useRef} from 'react';

function Navbar() {
  const focus = useRef("");

  return (
    <nav className="navbar vw-95 mt-3">

      <div className="left display w-50">
        <div className="display-center">
          <div>
            <img className="image" src={headshot} alt="headshot of Ryan" width="70" height="70"></img>
          </div>
          <div className="display-center w-30 ml-5">
            <p className="primary sub-two">Ryan Mau</p>
          </div>
        </div>
      </div>
      <div className="right">
        <div>
          <a className="primary" href="/#mywork">Projects</a>
        </div>
        <div>
          <a className="primary" href="/#contact">Contact Me</a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
